import React from "react";
import { Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { Container } from "@mui/system";
import banner1 from "../Assets/banner1.jpeg";
import banner2 from "../Assets/banner2.jpeg";
import banner3 from "../Assets/banner3.jpeg";
import banner4 from "../Assets/banner4.jpeg";

const LandingPage = ({ whatsappLink }) => {
    return (
        <div>
            <FeaturesCardWrapper
                id="about"
                style={{
                    marginTop: { xs: "1rem", md: "0rem" },
                }}
            >
                <Container>
                    <Grid container maxWidth={"lg"} spacing={{ xs: 3, md: 5 }}>
                        <Grid item xs={12} md={12}>
                            <div className="card" style={{ padding: "5%" }}>
                                <Typography variant="h5" color={"white"}>
                                    Mandatorily click on the 'JOIN us for FREE' button to join the Official WhatsApp Group to get access to everything mentioned on this website.
                                </Typography>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <div className="card" style={{ padding: "0 0 5% 0" }}>
                                <img src={banner1} />
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "7%",
                                }}>
                                    <a href={whatsappLink} target="_blank">
                                        JOIN us for FREE!
                                    </a>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <div className="card" style={{ padding: "0 0 5% 0" }}>
                                <img src={banner2} />
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "7%",
                                }}>
                                    <a href={whatsappLink} target="_blank">
                                        JOIN us for FREE!
                                    </a>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <div className="card" style={{ padding: "0 0 5% 0" }}>
                                <img src={banner3} />
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "7%",
                                }}>
                                    <a href={whatsappLink} target="_blank">
                                        JOIN us for FREE!
                                    </a>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <div className="card" style={{ padding: "0 0 5% 0" }}>
                                <img src={banner4} />
                                <div style={{
                                    marginTop: "7%",
                                }}>
                                    <a href={whatsappLink} target="_blank">
                                        JOIN us for FREE!
                                    </a>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <div className="card" style={{ color: "#C6BFEE", textAlign: "left", justifyContent: "left", alignItems: "start" }}>
                                <Typography variant="h4" style={{ padding: '4%' }}>
                                    Frequently Asked Questions
                                </Typography>
                                <br />
                                <div style={{ padding: "0 0 4% 4%" }}>
                                    <Typography fontSize={25} fontWeight={"bold"}>
                                        1. Who we are?
                                    </Typography>
                                    <br />
                                    <Typography fontSize={20} color={"#D4D2E3"} style={{ lineHeight: "2rem" }}>
                                        We are your Super Dream Placed Seniors who are placed at Amazon, Google, VMware etc from 2020-2022 Batches, here to help you get placed in these tough times of RECESSION.
                                    </Typography>
                                </div>
                                <br />
                                <div style={{ padding: "0 0 4% 4%" }}>
                                    <Typography fontSize={25} fontWeight={"bold"}>
                                        2. How do I join the group?
                                    </Typography>
                                    <br />
                                    <Typography fontSize={20} color={"#D4D2E3"} style={{ lineHeight: "2rem" }}>
                                        Click on "JOIN us for FREE" button, and you will join the group.
                                    </Typography>
                                </div>
                                <br />
                                <div style={{ padding: "0 0 4% 4%" }}>
                                    <Typography fontSize={25} fontWeight={"bold"}>
                                        3. What to do after I join the group?
                                    </Typography>
                                    <br />
                                    <Typography fontSize={20} color={"#D4D2E3"} style={{ lineHeight: "2rem" }}>
                                        Stay active in the group. All the important details & meeting link of the online placement help webinar that we are going to conduct will be shared in the group.
                                    </Typography>
                                </div>
                                <br />
                                <div style={{ padding: "0 0 4% 4%" }}>
                                    <Typography fontSize={25} fontWeight={"bold"}>
                                        4. What will be covered in the meet?
                                    </Typography>
                                    <br />
                                    <Typography fontSize={20} color={"#D4D2E3"} style={{ lineHeight: "2rem" }}>
                                        All the placement help including everything mentioned above in this website will be provided through the meet/online webinar.
                                    </Typography>
                                </div>
                                <br />
                                <div style={{ padding: "0 0 4% 4%" }}>
                                    <Typography fontSize={25} fontWeight={"bold"}>
                                        5. What if I miss the meet?
                                    </Typography>
                                    <br />
                                    <Typography fontSize={20} color={"#D4D2E3"} style={{ lineHeight: "2rem" }}>
                                        Meet will be conducted only once and no recordings will be provided. Set a reminder once the timings are declared in the group so that you don't miss out.
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>

            </FeaturesCardWrapper>
        </div>
    );
};

const FeaturesCardWrapper = styled.div`
    margin: 2rem 0 7rem 0;
    borderRadius: 1rem;
  
    img {
        width: 100%;
    }

    a {
        display: block;
        width: 15rem;
        background: #A95B95;
        padding: 5%;
        text-align: center;
        border-radius: 5rem;
        color: white;
        font-weight: bold;
        line-height: 25px;
    }

    .card {
        width: 100%;
        display: flex;
        border-radius: 5px;
        background-color: #140230;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
`;

export default LandingPage;
