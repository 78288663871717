import React from "react";
import styled from "styled-components";
import { Card, CardContent, Grid, Typography } from "@mui/material";

export default function PlacekitPlanPricing({ pricedata }) {
  return (
    <Wrapper>
      <Grid container justifyContent="center" spacing={6}>
        {/* <Grid item sm={6}>
          <Card
            className="root"
            style={{
              backgroundColor: "#140230",
              color: "white",
              borderRadius: "1rem",
            }}
          >
            <CardContent>
              <Typography className="title">PLAN A</Typography>
              <hr />
              <Grid container style={{ margin: "3% 0" }}>
                <Grid
                  item
                  sm={5}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "45px",
                      fontWeight: "550",
                    }}
                    variant="h5"
                    component="h2"
                  >
                    ₹ {pricedata.priceplanA}
                  </Typography>
                  <Typography className="pos">Registration Token</Typography>
                </Grid>

                <Grid
                  item
                  sm={7}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "25px",
                      fontWeight: "550",
                    }}
                    variant="h5"
                    component="h2"
                  >
                    Fees
                  </Typography>

                  <table className="table" border="1">
                    <tbody>
                      <tr className="tr">
                        <th className="th1">
                          Pay 50% from first months salary after getting placed
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </Grid>

                <table className="table" border="1">
                  <tbody>
                    <tr className="tr">
                      <th className="th1">Pay ₹ 0 if Unplaced</th>
                    </tr>
                  </tbody>
                </table>
              </Grid>

              <hr />
            </CardContent>
          </Card>
        </Grid> */}

        <Grid item sm={6}>
          <Card
            className="root"
            style={{
              backgroundColor: "#140230",
              color: "white",
              borderRadius: "1rem",
            }}
          >
            <CardContent>
              <Typography className="title">
                PLAN A (with Scholarship)
              </Typography>

              <hr style={{ margin: "3% 0" }} />

              <Typography
                style={{
                  // marginTop: "25px",
                  fontSize: "45px",
                  fontWeight: "550",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                variant="h5"
                component="h2"
              >
                ₹ {pricedata.priceplanA}
              </Typography>
              <Typography className="pos">Registration Token</Typography>

              <hr style={{ margin: "3% 0" }} />

              <Typography
                style={{
                  fontSize: "45px",
                  fontWeight: "550",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                variant="h5"
                component="h2"
              >
                <span
                  style={{
                    margin: " 0 1rem 0 0",
                    fontSize: "1.6rem",
                    WebkitTextFillColor: "#a2a2a2",
                    textDecoration: "line-through",
                  }}
                >
                  {pricedata?.cutPricing}
                </span>
                {pricedata.pricing}
              </Typography>
              <Typography className="pos">Course Fee</Typography>

              <br />

              <Typography>
                This plan of ₹0 course fee and getting the course at just the
                registration token(₹1999){" "}
                {pricedata.programName === "Placement Scholarship 2024"
                  ? "is applicable only for first 50 students who register & their college is not in TOP-50 NIRF Rankings. Register now, claim your scholarship, only few seats remaining."
                  : "is applicable only for first 100 students who score greater than 50% in the following 5 minute scholarship test. Appear now & claim your scholarship, only few seats remaining."}
              </Typography>

              {pricedata.programName !== "Placement Scholarship 2024" && (
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSevPOnwTvY-Chx8hSpJMQhT2V-Ia0C3kOArzlkTnE_R5TThoQ/viewform"
                  target="_blank"
                >
                  <button
                    className="btn-giveTest btn-giveTest-responsive"
                    style={{
                      width: "15rem",
                      marginTop: "2rem",
                    }}
                  >
                    Give Scholarship Test
                  </button>
                </a>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item sm={6}>
          <Card
            className="root"
            style={{
              backgroundColor: "#140230",
              color: "white",
              borderRadius: "1rem",
            }}
          >
            <CardContent>
              <Typography className="title">
                PLAN B (without Scholarship)
              </Typography>
              <hr style={{ margin: "3% 0" }} />

              <Typography
                style={{
                  // marginTop: "25px",
                  fontSize: "45px",
                  fontWeight: "550",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                variant="h5"
                component="h2"
              >
                ₹ {pricedata.priceplanA}
              </Typography>
              <Typography className="pos">Registration Token</Typography>

              <hr style={{ margin: "3% 0" }} />

              <Typography
                style={{
                  fontSize: "45px",
                  fontWeight: "550",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                variant="h5"
                component="h2"
              >
                + {pricedata.cutPricing}
              </Typography>
              <Typography className="pos">Course Fee</Typography>

              <hr style={{ margin: "3% 0" }} />

              <Typography
                style={{
                  fontSize: "45px",
                  fontWeight: "550",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                variant="h5"
                component="h2"
              >
                ₹ 10999
              </Typography>
              <Typography className="pos">Total fees</Typography>

              {/* <Typography
                style={{
                  // marginTop: "25px",
                  fontSize: "25px",
                  fontWeight: "550",
                }}
                variant="h5"
                component="h2"
              >
                What You Get?
              </Typography>

              <table className="table" border="1">
                <tbody>
                  <tr className="tr">
                    <td className="td1">Dream Companies' Referral Provided</td>
                    <td className="td">
                      <DoneOutline style={{ color: "green" }} />
                    </td>
                  </tr>
                  <tr className="tr">
                    <td className="td1">50+ Placement Opportunities</td>
                    <td className="td">
                      <DoneOutline style={{ color: "green" }} />
                    </td>
                  </tr>
                  <tr className="tr">
                    <td className="td1">FullStack Training & Internship</td>
                    <td className="td">
                      <DoneOutline style={{ color: "green" }} />
                    </td>
                  </tr>
                  <tr className="tr">
                    <td className="td1">Industry level Project building</td>
                    <td className="td">
                      <DoneOutline style={{ color: "green" }} />
                    </td>
                  </tr>
                  <tr className="tr">
                    <td className="td1">2 Placement Training Platforms</td>
                    <td className="td">
                      <DoneOutline style={{ color: "green" }} />
                    </td>
                  </tr>
                  <tr className="tr">
                    <td className="td1">
                      20+ hours of Placement Training classes on all topics
                    </td>
                    <td className="td">
                      <DoneOutline style={{ color: "green" }} />
                    </td>
                  </tr>
                  <tr className="tr">
                    <td className="td1">
                      Placement Mentorship & Guidance by SuperDream placed
                      seniors
                    </td>
                    <td className="td">
                      <DoneOutline style={{ color: "green" }} />
                    </td>
                  </tr>
                </tbody>
              </table> */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  -webkit-text-fill-color: initial;
  text-align: center;
  margin: 5rem;
  margin-top: 3rem;

  .root {
    /* min-width: 250; */
    margin-bottom: 50px;
    /* background-color: #1e1c1c; */
    // min-height: 530px;
    &:hover {
      margin-top: -10px;
      border: 2px solid transparent;
      border-image-slice: 1;
      animation: myEffect 3000ms linear infinite;
    }
  }
  @keyframes myEffect {
    0% {
      border-image: linear-gradient(
        to bottom right,
        #b827fc 0%,
        #2c90fc 25%,
        #b8fd33 50%,
        #fec837 75%,
        #fd1892 100%
      );
      border-image-slice: 1;
    }
    50% {
      border-image: linear-gradient(
        45deg,
        #fb0094,
        #00f,
        #0f0,
        #ff0,
        #f00,
        #fb0094,
        #00f,
        #0f0,
        #ff0,
        #f00
      );
      border-image-slice: 1;
    }
    100% {
      border-image: linear-gradient(
        to bottom right,
        #b827fc 0%,
        #2c90fc 25%,
        #b8fd33 50%,
        #fec837 75%,
        #fd1892 100%
      );
      border-image-slice: 1;
    }
  }
  .title {
    font-size: 40px;
    font-weight: 600;
    color: #8831ff;
  }
  .pos {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .table {
    border: 2px solid #9874ff;
    width: 85%;
    margin: auto;
    margin-top: 25px;
  }
  tr {
    border-bottom: 1px solid #9874ff;
  }
  .th {
    border-bottom: 1px solid #9874ff;
    border-left: 2px solid #9874ff;
    font-size: 20px;
    padding: 10px;
  }
  .th1 {
    border-bottom: 1px solid #9874ff;
    font-size: 20px;
    padding: 10px;
  }
  .td {
    text-align: center;
    border-left: 2px solid #9874ff;
    font-size: 20px;
    padding: 10px;
    width: 20%;
  }
  .td1 {
    text-align: center;
    font-size: 20px;
    padding: 10px;
    width: 80%;
  }
`;
