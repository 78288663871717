import { configureStore } from "@reduxjs/toolkit";
import { o1CoreApi } from "./o1Core";

export default configureStore({
  reducer: {
    [o1CoreApi.reducerPath]: o1CoreApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(o1CoreApi.middleware),
});
