import MainHomePage from "./pages/Homepage";
import { Routes, Route } from "react-router-dom";
import DSA from "./pages/DSA";
import Super50 from "./pages/Super50";
import PlacePrep360 from "./pages/PlacePrep360";
import ThousandX from "./pages/ThousandX";
import PlaceKit from "./pages/PlaceKit";
import CoreSubjectsForPlacements from "./pages/CoreSubjectsForPlacements";
import LandingPage from "./pages/LandingPage";
import { Navigate } from "react-router-dom";
import JobSwitchPro from "./pages/JobSwitchPro";
import InterviewGenie from "./pages/InterviewGenie";
import PlacementScholarship2024 from "./pages/PlacementScholarship2024";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<MainHomePage />} />
        <Route
          path="/landing1"
          element={
            <LandingPage
              whatsappLink={"https://chat.whatsapp.com/GwKAJc1XxUP9nxNLwDSFet"}
            />
          }
        />
        <Route
          path="/landing2"
          element={
            <LandingPage
              whatsappLink={"https://chat.whatsapp.com/LPrtEcAgkNwI5uTzytMdd0"}
            />
          }
        />
        <Route path="/super40" element={<Super50 />} />
        <Route path="/job-switch-pro" element={<JobSwitchPro />} />
        <Route path="/sdebootcamp" element={<PlacePrep360 />} />
        <Route path="/csfundamentals" element={<CoreSubjectsForPlacements />} />
        <Route path="/placement-scholarship" element={<PlaceKit />} />
        <Route
          path="/placement-scholarship-2024"
          element={<PlacementScholarship2024 />}
        />
        <Route
          path="/placekit"
          element={<Navigate to="/placement-scholarship" />}
        />
        <Route path="/1000x" element={<ThousandX />} />
        <Route path="/interview-genie" element={<InterviewGenie />} />
      </Routes>
    </>
  );
}

export default App;

