import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../config";

export const o1CoreApi = createApi({
  reducerPath: "o1CoreApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_API,
  }),
  endpoints: (builder) => ({
    getWebsiteConfig: builder.query({
      query: (args) =>
        `/coderun-admin/o1-config/?program=${args.program}` +
        (args?.key ? `&key=${args.key}` : ""),
    }),
  }),
});

export const { useGetWebsiteConfigQuery } = o1CoreApi;
