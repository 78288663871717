import React from "react";
import { Box, Grid } from "@mui/material";
import styled from "styled-components";
import beginLogo from "../Assets/1.png";
import handsOnLogo from "../Assets/11.png";
import keepItSimplelogo from "../Assets/33.png";
import { Container } from "@mui/system";

const PlacekitFeaturesCard = ({ data }) => {
  return (
    <FeaturesCardWrapper
      id="about"
      style={{
        marginTop: { xs: "1rem", md: "0rem" },
      }}
    >
      <Container>
        <Grid container maxWidth={"lg"} spacing={{ xs: 3, md: 5 }}>
          <Grid item xs={12} md={4}>
            <div
              className="card"
              style={{
                borderRadius: "1rem",
              }}
            >
              <div className="imgdiv">
                <img
                  src={
                    "https://drive.google.com/thumbnail?id=1D0WcYS1x3GYJHznDqsf2FKBD4cPRIN9f"
                  }
                  alt=""
                />
              </div>
              {/* <div className="contentdetails">
                <h4>No Fees Until Placed</h4>
                <br />
                <p>
                  Focus on placements rather than paying hefty fees, we are your
                  seniors and want to help you, just pay the registration token,
                  fees should be paid only once you get placed.
                </p>
              </div> */}
              <div className="contentdetails">
                <h4>100% Scholarship with No Course Fee</h4>
                <br />
                <p>
                  {data.programName == "Placement Scholarship 2024"
                    ? "First 50 students to register whose colleges are not in TOP-50 NIRF Rankings get this placement training"
                    : "First 100 to score more than 50% marks in the short 5 minute scholarship test get this placement training"}{" "}
                  without paying any course fee, only registration token needs
                  to be paid by scholarship winners.
                </p>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <div
              className="card"
              style={{
                borderRadius: "1rem",
              }}
            >
              <div className="imgdiv">
                <img
                  src={
                    "https://drive.google.com/thumbnail?id=1VB9XdyWWBkQMkALkunjjOVkktq-yh2Dd"
                  }
                  alt=""
                />
              </div>
              <div className="contentdetails">
                <h4>Placement Drives Provided</h4>
                <br />
                <p>
                  Sit for our Placement Drives of 15+ Dream Companies, perform
                  well & grab your dream job through us.
                </p>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <div
              className="card"
              style={{
                borderRadius: "1rem",
              }}
            >
              <div className="imgdiv">
                <img
                  src={
                    data.features.firstimg ? data.features.firstimg : beginLogo
                  }
                  alt=""
                />
              </div>
              <div className="contentdetails">
                <h4>
                  {data.features.firstheading
                    ? data.features.firstheading
                    : "Beginner Friendly"}
                </h4>
                <br />
                <p>
                  {data.features.first
                    ? data.features.first
                    : "Our programs are tailor made and are beginner friendly. We want all to prosper and none to be left behind."}
                </p>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <div
              className="card"
              style={{
                borderRadius: "1rem",
              }}
            >
              <div className="imgdiv">
                <img
                  src={
                    data.features.thirdimg
                      ? data.features.thirdimg
                      : keepItSimplelogo
                  }
                  alt=""
                />
              </div>
              <div className="contentdetails">
                <h4>
                  {data.features.thirdheading
                    ? data.features.thirdheading
                    : "Keep it Simple"}
                </h4>
                <br />
                <p>
                  {data.features.third
                    ? data.features.third
                    : "We believe in simplicity. Learning can be very simple if you have the right approach. Keep simple, learn more."}
                </p>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <div
              className="card"
              style={{
                borderRadius: "1rem",
              }}
            >
              <div className="imgdiv">
                <img
                  src={
                    "https://drive.google.com/thumbnail?id=1VB9XdyWWBkQMkALkunjjOVkktq-yh2Dd"
                  }
                  alt=""
                />
              </div>
              <div className="contentdetails">
                <h4>Full Stack Internship</h4>
                <br />
                <p>
                  Full Stack Training/Internship by Amazon Placed Senior along
                  with Internship Certificate to enhance your resume and have a
                  better shot towards placements.
                </p>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <div
              className="card"
              style={{
                borderRadius: "1rem",
              }}
            >
              <div className="imgdiv">
                <img
                  src={
                    "https://drive.google.com/thumbnail?id=1GhyCetsXgBrpAx6jz2mVRq-RwvmcKvSm"
                  }
                  alt=""
                />
              </div>
              <div className="contentdetails">
                <h4>Live Project Building</h4>
                <br />
                <p>
                  To impress the interviewer & ultimately crack placements we
                  will build a live industry level project from scratch &
                  showcase in our resume.
                </p>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <div
              className="card"
              style={{
                borderRadius: "1rem",
              }}
            >
              <div className="imgdiv">
                <img
                  src={
                    "https://drive.google.com/thumbnail?id=1GhyCetsXgBrpAx6jz2mVRq-RwvmcKvSm"
                  }
                  alt=""
                />
              </div>
              <div className="contentdetails">
                <h4>Mock Practice Tests</h4>
                <br />
                <p>
                  Not just training but this program also includes rigorous
                  practice through our well curated mock tests designed to make
                  you placement ready.
                </p>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <div
              className="card"
              style={{
                borderRadius: "1rem",
              }}
            >
              <div className="imgdiv">
                <img
                  src={
                    data.features.secondimg
                      ? data.features.secondimg
                      : handsOnLogo
                  }
                  alt=""
                />
              </div>
              <div className="contentdetails">
                <h4>
                  {data.features.second
                    ? data.features.secondheading
                    : "Hands On"}
                </h4>
                <br />
                <p>
                  {data.features.second
                    ? data.features.second
                    : "Create from your learning. We focus on hands on. We believe practical knowledge is as important as conceptual knowledge."}
                </p>
              </div>
            </div>
          </Grid>

          {data.programName == "Placement Scholarship 2024" && (
            <Grid item xs={12} md={4}>
              <div
                className="card"
                style={{
                  borderRadius: "1rem",
                }}
              >
                <div className="imgdiv">
                  <img src={"https://i.postimg.cc/8PZgpCvz/44.png"} alt="" />
                </div>
                <div className="contentdetails">
                  <h4>Last Years Placed Students</h4>
                  <br />
                  <p>
                    We are transparent & our results is our proof of work, check
                    the list of students from last years' programs who cracked
                    superdream packages despite not being from top colleges.
                  </p>
                  <Box
                    display={"flex"}
                    justifyContent={{ xs: "center", md: "center" }}
                  >
                    <a
                      href="https://docs.google.com/spreadsheets/d/1GtTAfQblLBjcdZqahQKrMGypSVjturz1YEUN4xxLmqA"
                      target="_blank"
                    >
                      <button
                        className="btn-giveTest btn-giveTest-responsive"
                        style={{
                          width: "13rem",
                          lineHeight: "25px",
                          letterSpacing: "1px",
                        }}
                      >
                        Placement Record
                      </button>
                    </a>
                  </Box>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </Container>
    </FeaturesCardWrapper>
  );
};

const FeaturesCardWrapper = styled.div`
  margin: 5rem 0;
  .card {
    width: 100%;
    height: 25rem;
    display: flex;
    padding: 2rem;
    background-color: #140230;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .imgdiv {
      width: 7rem;
      img {
        width: 100%;
      }
    }
    .contentdetails {
      color: #ffffff;
      text-align: center;
      h4 {
        font-size: 1.8rem;
        line-height: -1rem;
      }
      p {
        opacity: 70%;
        font-size: 1rem;
        line-height: 1.6rem;
      }
    }

    @media screen and (max-width: 706px) {
      margin: 0.75rem 0;
      .card {
        height: 150rem;
      }
    }
  }
`;

export default PlacekitFeaturesCard;
