import React from "react";
import FooterNew from "../components_template/components/Footer/FooterNew";
import Testimonial from "../components/Testimonial2";
import CompanySlider from "../components/CompanySlider";
import Stats from "../components/Stats";
import PagesData from "./PagesData";
import Navbar from "../components/Navbar";
import Pricing from "../components/Pricing";
import MainContent from "../components_template/components/TextContent/MainContent";
import ReferralSlider from "../components/ReferralSlider";
import YouWillGet from "../components_template/components/YouWillGet";
import ApplyNowBtn from "../components/ApplyNowBtn";
import TimelineForSuper40 from "../components/TimelineForSuper40";
import FAQ from "../components/FAQ";
import FeaturesCardV2 from "../components/FeaturesCardV2";
import YoutubeVideo from "../components/YoutubeVideo";
import AboutInstructor from "../components/AboutInstructor";

const InterviewGenie = () => {
  const programdata = PagesData.InterviewGenie;

  return (
    <>
      <div
        style={{
          backgroundColor: "#0b001a",
          scrollBehavior: "smooth",
          userSelect: "none",
        }}
      >
        <Navbar />
        <MainContent data={programdata} />
        <Stats data={programdata} />
        <ReferralSlider />
        <YoutubeVideo />
        <FeaturesCardV2 data={programdata} />
        <AboutInstructor data={programdata} />
        <Pricing data={programdata} />
        <ApplyNowBtn data={programdata} />
        {programdata.showyouwillget ? (
          <YouWillGet data={programdata} />
        ) : (
          <div></div>
        )}
        {programdata.showtimeline ? (
          <TimelineForSuper40 data={programdata} />
        ) : (
          <div></div>
        )}
        <CompanySlider />
        <FAQ />
        <Testimonial />
        <FooterNew name={programdata} />
      </div>
    </>
  );
};

export default InterviewGenie;
