import React from "react";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";
import { Box } from "@mui/material";

const ApplyNowBtn = (props) => {
  return (
    <ApplyNowBtnStyled
      style={{ marginTop: props.data.shownote ? "-2rem" : "-8rem" }}
    >
      {/* {props.data.shownote ? (
          <p style={{ color: "#9874ff", fontWeight: "bold", fontSize: "20px" }}>
            Note : The program is free if we fail to land you a job. 100%
            refund, no questions asked.
          </p>
        ) : (
          ""
        )} */}
      {props.data.programName === "Super40" && (
        <Box
          style={{
            display: "flex",
            textAlign: "center",
            width: "75%",
            margin: "auto",
          }}
        >
          <p style={{ color: "#9874ff", fontWeight: "bold", fontSize: "20px" }}>
            Note: One of our previous batches has a{" "}
            <b>100% placement track record</b>, we aim to get each & every student
            placed with a good job. As a proof, check out the detailed list of
            each of our students placement record below.
          </p>
        </Box>
      )}
      {props.data.programName === "Placement Scholarship" && (
        <Box
          style={{
            display: "flex",
            textAlign: "center",
            width: "75%",
            margin: "auto",
          }}
        >
          <p style={{ color: "#9874ff", fontWeight: "bold", fontSize: "20px" }}>
            Note: We are transparent & our results is our proof of work, check
            the list of students from last years' programs who cracked
            superdream packages despite not being from top colleges
          </p>
        </Box>
      )}
      {(props.data.programName === "Super40" ||
        props.data.programName === "Placement Scholarship") && (
        <p>
          <a
            href="https://docs.google.com/spreadsheets/d/1GtTAfQblLBjcdZqahQKrMGypSVjturz1YEUN4xxLmqA"
            target="_blank"
          >
            <button
              className="btn-giveTest btn-giveTest-responsive"
              style={{
                width: "20rem",
                marginTop: "2rem",
              }}
            >
              Previous Batch Placements
            </button>
          </a>
        </p>
      )}
      <HashLink smooth to={props.data.applyNowURL}>
        <button
          className="btn-giveTest mobileres explore-btn"
          style={{ marginTop: "30px" }}
        >
          {props.data.programName === "PlaceKit" ? "Enroll" : "Apply"} Now
        </button>
      </HashLink>
    </ApplyNowBtnStyled>
  );
};

export default ApplyNowBtn;

const ApplyNowBtnStyled = styled.div`
  text-align: center;
  margin: 0 0 4rem 0;
  transform-origin: 0 0;
  @media screen and (max-width: 600px) {
    .mobileres {
      transform: scale(1);
    }
  }
  @media screen and (max-width: 400px) {
    .btn-giveTest-responsive {
      width: 18rem !important;
    }
  }
`;
