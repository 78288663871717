import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import referralImg from "../Assets/referralImg2.png";

const ReferralsContainer = () => {
  return (
    <ReferralsContainerStyled>
      <Typography
        variant="h3"
        sx={{
          margin: "3rem 0",
          textAlign: "center",
          color: "white",
          fontSize: "3.0rem",
          fontWeight: "bold",
          "@media (max-width:480px)": {
            fontSize: "1.8rem",
          },
        }}
      >
        We provide Referrals at
      </Typography>
      <img src={referralImg} alt="" />
    </ReferralsContainerStyled>
  );
};

const ReferralsContainerStyled = styled.div`
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  h1 {
    text-align: center;
    font-family: "Muli";
    color: white;
    font-weight: bold;
    margin: 3rem 0;
    font-size: 3rem;
  }

  img {
    width: 85%;
  }

  @media screen and (max-width: 576px) {
    h1 {
      font-size: 2rem;
    }
  }
`;

export default ReferralsContainer;
